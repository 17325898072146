<template>
	<div id="createmeet">
		<div class="left">
			<h1 class="title" v-if="this.registerForm.meetId">01-修改会议信息</h1>
			<h1 class="title" v-else>01-创建会议</h1>
			<el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="top" v-loading="loading">
				<el-form-item label="会议类型">
					<p class="meetingType">{{registerForm.projectType}}</p>
				</el-form-item>
				<el-form-item label="所属项目">
					<p class="meetingType">{{projectInfo.projectName}}</p>
				</el-form-item>
				<el-form-item label="会议主题" prop="conferenceTheme">
					<el-input v-model="registerForm.conferenceTheme" placeholder="请输入会议主题"></el-input>
				</el-form-item>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="会议类型">
							<el-radio-group v-model="registerForm.meetingFormat" @input="reaioInput">
								<el-radio label="ONLINE">线上</el-radio>
								<el-radio label="OFFLINE">线下</el-radio>
								<el-radio label="OTHER">腾讯会议</el-radio>
								<el-radio label="ONLYSIGN">其他</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="会议时间" prop="timestamp">
							<el-date-picker v-model="registerForm.timestamp" value-format="timestamp" type="datetime"
								style="width: 100%" placeholder="请选择会议开始时间"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="参会地点" prop="location" v-if="registerForm.meetingFormat=='OFFLINE'">
					<el-cascader v-model="registerForm.location" :options="regionTree" clearable style="width: 100%;"
						:props="{ value:'name', label:'name'}" @change="cascaderChange"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="position" v-if="registerForm.meetingFormat=='OFFLINE'">
					<el-input v-model="registerForm.position" placeholder="请输入详细地址">
						<el-button slot="append" icon="el-icon-map-location"
							@click="mapShow = true;address = registerForm.position">使用地图定位</el-button>
					</el-input>
				</el-form-item>
				<div label="参会医生" class="attendingDoctor">
					<div class="label">参会医生</div>
					<div class="option">
						<el-button class="addStanding" type="primary" plain icon="el-icon-circle-plus-outline"
							@click="addStanding = true">新增参会身份</el-button>
						<el-dialog v-if="addStanding" :visible.sync="addStanding" width="500px">
							<div class="title">新增参会身份</div>
							<el-form ref="StandForm" :model="StandForm" :rules="rules" label-width="120px"
								:modal-append-to-body="true" :append-to-body="true" label-position="top">
								<el-form-item label="参会身份名称" prop="roleName">
									<el-input v-model="StandForm.roleName" placeholder="请输入您要新增的参会身份名称"></el-input>
								</el-form-item>
								<el-form-item label="劳务形式">
									<el-input v-model="StandForm.roleShow" placeholder="劳务形式"></el-input>
								</el-form-item>
								<el-form-item label="劳务金额" prop="roleMoney">
									<el-input v-model="StandForm.roleMoney" placeholder="请输入该参会身份的劳务金额"></el-input>
								</el-form-item>
							</el-form>
							<span slot="footer" class="dialog-footer">
								<el-button @click="addStanding = false;resetForm('StandForm')">取 消</el-button>
								<el-button type="primary" @click="addStandingFun('StandForm')">确 定</el-button>
							</span>
						</el-dialog>
					</div>
				</div>

				<el-form-item class="doctors" :label="item.roleName" v-for="item in roleList" :key="item.id">
					<el-button type="text" icon="el-icon-delete" class="delete" @click="deleteFun(item)">删除</el-button>
					<div class="meetingType" @click="showDoctorsFun(`${item.id}`)">
						<p class="docs" v-if="doctorCk[`${item.id}`][0]">
							<span v-for="(item2,k) in doctorCk[`${item.id}`]" :key="k">
								{{item2.fullName}}
								<template v-if="k<doctorCk[`${item.id}`].length-1">,</template>
							</span>
						</p>
						<span class="placeholder" v-else>请选择参会人员 </span>
						<span class="add">添加<i class="el-icon-plus"></i></span>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="right">
			<h1 class="title">02-创建会议</h1>
			<el-form :model="registerFormImg" :rules="rules" ref="registerFormImg" label-position="top"
				v-loading="loading">
				<el-form-item label="会议背景图(主KV)" prop="bgImage">
					<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
						:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" :file-list="fileList1">
						<el-image :src="registerFormImg['1'].file?registerFormImg['1'].file:registerFormImg['1'].url"
							v-if="registerFormImg['1']" fit="cover" class="avatar"></el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="会议海报" prop="bill">
					<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
						:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload" :file-list="fileList2">
						<img :src="registerFormImg['2'].file?registerFormImg['2'].file:registerFormImg['2'].url"
							v-if="registerFormImg['2']" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</div>
		<div class="options">
			<el-button class="bank" :loading="loading" @click="bank()">返回</el-button>
			<el-button class="next" :loading="loading" @click="onSubmit('registerForm')">下一步</el-button>
		</div>

		<el-dialog class="doctorList" :visible.sync="persShow" v-loading="loading">
			<h1 class="title">医生列表</h1>
			<div class="searth">
				<el-input @change="searthDoctorFun()" v-model="nameKeyword" prefix-icon="el-icon-search" clearable
					placeholder="搜索医生姓名/手机号/医院"></el-input>
				<el-select v-model="departVal" placeholder="按科室筛选" clearable filterable @change="searthDoctorFun()">
					<el-option v-for="item in department" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
				<el-select v-model="titVal" placeholder="按职称筛选" clearable filterable @change="searthDoctorFun()">
					<el-option v-for="item in title" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
				<el-select v-model="orderBy" placeholder="按职称筛选" filterable @change="searthDoctorFun()">
					<el-option label="综合排序" value="综合排序"> </el-option>
					<el-option label="时间排序" value="时间排序"> </el-option>
				</el-select>
				<el-button type="primary" @click="searthDoctorFun()">搜索</el-button>
				<el-button type="primary" @click="creatorDoctor()">新增医生</el-button>
				<el-button type="primary" @click="persShow = false">确认选择</el-button>
			</div>
			<div class="list">
				<div class="item" v-for="item in doctorList" :key="item.id" @click="checkDoctor(item)">
					<img src="../../assets/imgs/yisheng.svg" alt="">
					<div class="docInfo">
						<div class="name">{{item.fullName?item.fullName:item.phoneNumber}}</div>
						<div class="hospital">{{item.hospital}}</div>
						<div class="tit">
							<span v-if="item.department">
								<i class="el-icon-timer"></i>
								{{item.department}}</span>
							<span v-if="item.title">
								<i class="el-icon-location"></i>
								{{item.title}}
							</span>
						</div>
						<i class="icon el-icon-success" style="color: #2979ff; font-size: 40px;"
							v-if="doctorIds[doctorType]&&doctorIds[doctorType].includes(item.id)"></i>
					</div>
				</div>
				<div class="null" v-if="!doctorList[0]">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<p>列表没有匹配项</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getDoctorList"
				:hide-on-single-page="false"></el-pagination>
		</el-dialog>

		<el-dialog v-if="mapShow" :visible.sync="mapShow" width="1200px">
			<div class="mapBox">
				<div class="searthBox">
					<!-- <el-input placeholder="请输入关键词" v-model="address" clearable> </el-input> -->
					<el-input class="inline-input" v-model="address" placeholder="请输入关键词" clearable
						style="width: 100%;"></el-input>
					<el-button type="primary" @click="mapShow = false"
						style="width: 200px;margin-left: 10px;">确认</el-button>
				</div>
				</el-input>
				<baidu-map class="map" ak="uYg4DrBPzGF4VxY1VepBisbGxZi3X4py" :center="map.center" :zoom="map.zoom"
					@ready="mapInit" @click="getClickInfo">
					<bm-view class="mapView"></bm-view>
					<bm-city-list anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-city-list>
					<bm-local-search class="bmLocalSearch" :keyword="address" :auto-viewport="true" :forceLocal="false"
						@infohtmlset="infohtmlset"></bm-local-search>
				</baidu-map>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		getMeetRoleList,
		doctorList,
		createMeeting,
		updateMeeting,
		getUpdateMeetInfo,
		updateMeetingImage,
		getProjectById
	} from '@/request/api.js';
	import regionTree from '@/assets/regionTree.json'
	import title from '@/assets/title.json';
	import department from '@/assets/department.json';
	import baseUrl from "@/request/config.js"
	import axios from 'axios';
	export default {
		name: 'createmeet',
		data() {
			return {
				addStanding: false,
				StandForm: {
					roleName: '',
					roleMoney: ''
				},
				baseUrl: '',
				loading: false,
				registerForm: {
					meetingFormat: 'ONLINE', // 会议类型
					projectId: '', // 所属项目ID
					meetId: '', // 会议id
					projectType: '', // 所属项目
					startTime: '', // 会议开始时间
					timestamp: '', // 会议开始时间戳
					conferenceTheme: '', // 会议主题
					doctors: '', // 选择参会医生
					cover: '', // 直播封面
					placard: '', // 海报
					location: '',
					position: ''
				},
				projectInfo: '',
				rules: {
					conferenceTheme: [{
						required: true,
						message: '请输入会议主题',
						trigger: 'change'
					}],
					roleName: [{
						required: true,
						message: '请输入身份名称',
						trigger: 'change'
					}],
					roleMoney: [{
						required: true,
						message: '请输入劳务金额',
						trigger: 'change'
					}],
					timestamp: [{
						required: true,
						message: '请选择会议开始时间',
						trigger: 'change'
					}],
					doctors: [{
						validator: (rule, value, callback) => {
							let flag = false;
							for (let key in this.doctorIds) {
								if (this.doctorIds[key].length) {
									flag = true;
									break;
								}
							};
							if (flag) {
								callback();
							} else {
								callback(new Error('请至少添加一个参会人员'));
							}
						},
						trigger: 'change'
					}],
					bgImage: [{
						validator: (rule, value, callback) => {
							if (this.registerFormImg[1]) {
								callback();
							} else {
								callback();
								// callback(new Error('请上传会议背景图(KV)'));
							}
						},
						trigger: 'blur'
					}],
					bill: [{
						validator: (rule, value, callback) => {
							if (this.registerFormImg[2]) {
								callback();
							} else {
								callback();
								// callback(new Error('请上传会议海报(会议宣传图)'));
							}
						},
						trigger: 'blur'
					}]
				},

				fileList1: [],
				fileList2: [],
				dialogImageUrl: '',
				dialogVisible: false,
				registerFormImg: {
					1: '',
					2: ''
				},

				persShow: false,
				doctorType: '',
				roleList: [], // 身份列表
				doctorCk: {}, // 选中的医生
				doctorIds: {}, // 选中的医生

				nameKeyword: '',
				hospitalKeywords: '',
				phonenumberKeywords: '',
				page: 1,
				size: 10,
				total: 0,
				doctorList: '',
				checks: [],
				checked: [],

				department: '',
				departVal: '',
				title: '',
				titVal: '',
				orderBy: '综合排序',

				regionTree: '',

				mapShow: false,
				map: {
					center: {
						lat: 39.914894,
						lng: 116.404146
					},
					zoom: 13,
				},
				bMapObj: '',
				markerWinShow: true,
				address: '',
				surroundingPois: []
			}
		},
		created() {
			this.regionTree = regionTree;
			this.baseUrl = baseUrl;
			this.title = title;
			this.department = department;
			this.registerForm.projectId = this.$route.query.pId;
			this.registerForm.meetId = this.$route.query.mId;
			this.echo();
			this.getProjectDetails();
			this.getRoleListFun();
			this.getDoctorList();
			this.getProjectByIdFun();
			// console.log(this.$route.query.mId)
			if (this.registerForm.meetId) {
				this.getMeetingDetail();
			}
		},
		watch: {
			registerForm: {
				handler(nval, oval) {
					let data = {
						meetTit: nval.conferenceTheme,
						timestamp: nval.timestamp,
						registerFormImg: this.registerFormImg
					}
					setTimeout(() => {
						localStorage.setItem('cache', JSON.stringify(data));
					}, 300)
				},
				deep: true
			},
			registerFormImg: {
				handler(nval, oval) {
					let data = {
						meetTit: this.registerForm.conferenceTheme,
						timestamp: this.registerForm.timestamp,
						registerFormImg: nval
					}
					setTimeout(() => {
						localStorage.setItem('cache', JSON.stringify(data));
					}, 300)
				},
				deep: true
			}
		},
		mounted() {
			// console.log(this.getRandomNumber(12))
		},
		methods: {
			deleteFun(item) {
				this.roleList.map(item => item.id).map((id, idx) => {
					if (item.id == id) {
						this.roleList.splice(idx, 1);
						this.doctorCk[item.id] = [];
						this.doctorIds[item.id] = [];
						// this.roleList.map(item => { });
					}
				})
			},

			addStandingFun(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.addStanding = false;
						let stand = JSON.parse(JSON.stringify(this.StandForm));
						stand.id = this.getRandomNumber(12);
						this.roleList = [...this.roleList, stand];
						this.doctorCk[stand.id] = [];
						this.doctorIds[stand.id] = [];
						this.StandForm = {};
						// console.log(this.roleList)

					} else {
						return false;
					}
				});
			},

			getRandomNumber(digits) {
				var min = Math.pow(10, digits - 1);
				var max = Math.pow(10, digits) - 1;
				return Math.floor(Math.random() * (max - min + 1)) + min;
			},

			resetForm() {
				this.StandForm = {};
			},

			infohtmlset(e) {
				console.log(e)
				// this.address = e.address;
				this.registerForm.position = e.title
				this.registerForm.longitude = e.point.lng
				this.registerForm.latitude = e.point.lat
				// this.registerForm.location = [e.province, e.city]
			},

			querySearch(queryString, cb) {
				var restaurants = this.surroundingPois;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},

			createFilter(queryString) {
				return (restaurant) => {
					return (restaurant.address.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},

			addressSelect(item) {
				console.log(item)
			},

			getClickInfo(e) {
				// 创建地理编码实例
				const myGeo = new BMap.Geocoder();
				// 根据坐标逆解析地址
				myGeo.getLocation(new BMap.Point(e.point.lng, e.point.lat), (result) => {
					if (result) {
						this.address = result.address;
						this.surroundingPois = result.surroundingPois;
					}
				});
			},
			mapInit({
				BMap,
				map
			}) {
				this.bMapObj = map;
				// 添加鼠标滚动缩放
				this.bMapObj.enableScrollWheelZoom(false)
				// 添加缩放平移控件
				this.bMapObj.addControl(new BMap.NavigationControl());
				//添加比例尺控件ƒ
				this.bMapObj.addControl(new BMap.ScaleControl());
				//添加地图类型控件
				// this.bMapObj.addControl(new BMap.MapTypeControl());
				// 点击事件获取经纬度
				// this.bMapObj.addEventListener('click', (e) => {
				// 	console.log(e.point.lng, e.point.lat)
				// 	// // 创建中心点坐标
				// 	// this.map.center.lat = e.point.lat;
				// 	// this.map.center.lng = e.point.lng;
				// 	// var point = new BMap.Point(e.point.lng, e.point.lat);
				// 	// // 设置地图的中心点
				// 	// this.bMapObj.setCenter(point);
				// })

			},

			cascaderChange(e) {
				console.log(e)
			},

			getProjectByIdFun() {
				getProjectById({
					id: this.registerForm.projectId
				}).then(res => {
					if (res.code == 200) {
						this.projectInfo = res.data;
					}
				})
			},

			reaioInput(e) {
				console.log(e)
			},

			creatorDoctor() {
				this.$router.push({
					name: 'creatdoc',
					query: {
						from: 'creat',
						pId: this.$route.query.pId
					}
				})
			},

			// 缓存回显
			echo() {
				let cache = JSON.parse(localStorage.getItem("cache"));
				if (cache) {
					if (cache.timestamp) this.registerForm.timestamp = cache.timestamp;
					if (cache.meetTit) this.registerForm.conferenceTheme = cache.meetTit;
					if (cache.registerFormImg) this.registerFormImg = cache.registerFormImg;
				}
			},
			getMeetingDetail() {
				let data = {
					meetingId: this.registerForm.meetId
				};
				getUpdateMeetInfo(data).then(res => {
					if (res.code == 200) {
						this.registerForm.timestamp = res.data.meetingStartTime;
						this.registerForm.conferenceTheme = res.data.meetingTitle;
						this.registerForm.meetingFormat = res.data.meetingFormat;
						this.registerForm.location = res.data.location ? res.data.location.map(item => item
							.trim()) : '';
						this.registerForm.position = res.data.position;
						this.registerForm.longitude = res.data.longitude;
						this.registerForm.latitude = res.data.latitude;
						this.registerFormImg[1] = {
							url: res.data.bgImage
						};
						this.registerFormImg[2] = {
							url: res.data.bill
						};
						let ids = JSON.parse(JSON.stringify(res.data.users))
						for (let i in ids) {
							this.doctorCk[i] = ids[i];
							this.doctorIds[i] = ids[i].map(item => item.id)
						}
					}
				});
			},

			onSubmit(formName) {
				let submit1 = false;
				let submit2 = false;
				this.$refs['registerForm'].validate((valid) => {
					if (valid) {
						submit1 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				this.$refs['registerFormImg'].validate((valid) => {
					if (valid) {
						submit2 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				if (submit1 && submit2) {
					this.loading = true;
					this.updateMeetingFun(submit1, submit2)
				}
			},

			updateMeetingFun(flag1, flag2) {
				let data = {
					users: this.doctorCk,
					projectId: this.registerForm.projectId,
					meetingStartTime: this.registerForm.timestamp,
					meetingTitle: this.registerForm.conferenceTheme,
					meetingFormat: this.registerForm.meetingFormat,
					bgImage: this.registerFormImg[1].path,
					bill: this.registerFormImg[2].path,
					roleList: this.roleList
				};
				if (this.registerForm.location) {
					data.location = this.registerForm.location;
				}
				if (this.registerForm.position) {
					data.position = this.registerForm.position;
					data.longitude = this.registerForm.longitude;
					data.latitude = this.registerForm.latitude;
				}

				if (this.registerForm.meetId) {
					data.id = this.registerForm.meetId;
					updateMeeting(data).then(res => {
						if (res.code == 200) {
							localStorage.removeItem('cache');
							this.loading = false;
							this.$router.push({
								name: 'confmeet',
								query: {
									mId: res.data
								}
							})
							this.$message({
								message: '修改成功',
								type: 'success'
							});
							this.$notify({
								type: 'success',
								title: '修改成功',
								message: '修改会议信息成功',
								position: 'bottom-right'
							});
						}
					});
					// updateMeetingImage(data).then(res => {
					// 	if (res.code == 200) {
					// 		this.$message({
					// 			message: '直播间设置更新成功',
					// 			type: 'success'
					// 		});
					// 		// this.$router.push({
					// 		// 	name: 'confmeet',
					// 		// 	query: {
					// 		// 		mId: this.meetingId
					// 		// 	}
					// 		// })
					// 		// this.$notify({
					// 		// 	type: 'success',
					// 		// 	title: '更新成功',
					// 		// 	message: 'KV/海报更新成功',
					// 		// 	position: 'bottom-right'
					// 		// });
					// 	}
					// });
				} else {
					// httpRequestRrl = '/sign/live/assistant/createMeeting';
					createMeeting(data).then(res => {
						if (res.code == 200) {
							localStorage.removeItem('cache');
							this.loading = false;
							// console.log(res.data);
							this.$router.push({
								name: 'confmeet',
								query: {
									mId: res.data
								}
							})
							this.$message({
								message: '创建成功',
								type: 'success'
							});
							this.$notify({
								type: 'success',
								title: '创建成功',
								message: '会议创建成功',
								position: 'bottom-right'
							});
						}
					})
				};

				console.log(JSON.parse(JSON.stringify(data)));
			},

			handleAvatarSuccess1(res, file) {
				console.log(res, file);
				this.registerFormImg['1'] = {
					file: URL.createObjectURL(file.raw),
					path: res,
					url: res
				}
			},

			handleAvatarSuccess2(res, file) {
				this.registerFormImg['2'] = {
					file: URL.createObjectURL(file.raw),
					path: res,
					url: res
				}
			},

			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
				}
				return isLt20M;
			},

			// 选中医生
			checkDoctor(par) {
				for (let i in this.roleList) {
					let item = this.roleList[i];
					if (this.doctorType == `${item.id}`) {
						if (this.doctorIds[`${item.id}`].indexOf(par.id) == -1) {
							this.doctorCk[`${item.id}`] = [...this.doctorCk[`${item.id}`], ...[
								par
							]];
							this.doctorIds[`${item.id}`] = [...this.doctorIds[`${item.id}`], ...[
								par.id
							]];
						} else {
							this.doctorCk[`${item.id}`].splice(this.doctorIds[`${item.id}`].indexOf(par.id), 1);
							this.doctorIds[`${item.id}`].splice(this.doctorIds[`${item.id}`].indexOf(par.id), 1);
						}
					}
				}



				this.$forceUpdate();
				// console.log('doctorCk', JSON.parse(JSON.stringify(this.doctorCk)));
				// console.log('doctorIds', JSON.parse(JSON.stringify(this.doctorIds)));

			},

			// 打开选择医生页面
			showDoctorsFun(item) {
				// console.log(item);
				this.doctorType = item;
				this.persShow = true;
				this.doctorList = [];

				let checks = [];
				for (let i in this.doctorIds) {
					if (i != item) {
						let item = this.doctorIds[i];
						checks = [...checks, ...item];
					}
				};
				this.checks = checks;
				this.checked = this.doctorIds[item];

				this.getDoctorList();
			},

			getRoleListFun() {
				getMeetRoleList({
					meetingId: this.registerForm.meetId
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.data ? res.data : [];
						if (res.data[0]) {
							this.doctorType = res.data[0].id;
							res.data.map(item => {
								this.doctorCk[`${item.id}`] = [];
								this.doctorIds[`${item.id}`] = [];
							});
						}
					}
				})
			},

			getProjectDetails() {
				let projectDetails = JSON.parse(localStorage.getItem('projectDetails'));
				this.registerForm.projectType = projectDetails.projectType;
			},

			// 搜索操作
			searthDoctorFun() {
				this.page = 1;
				this.getDoctorList();
			},

			// 获取医生列表
			getDoctorList() {
				this.loading = true;
				let data = {
					current: this.page,
					size: this.size,
					checks: this.checks.join(','),
					checked: this.checked.join(',')
				};
				if (this.nameKeyword) data.fullName = this.nameKeyword;
				if (this.departVal) data.department = this.departVal;
				if (this.titVal) data.title = this.titVal;
				if (this.orderBy) data.orderBy = this.orderBy;
				doctorList(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.doctorList = res.data.records;
						this.total = res.data.total;
					}
				})
			},

			bank() {
				this.$confirm('此操作将清空已输入内容的缓存, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1)
					localStorage.removeItem('cache');
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#createmeet {
		display: flex;
		padding: 20px;
		flex-direction: row;
		flex-wrap: wrap;

		.title {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.left {
			flex: 2;
			padding: 20px;
			margin-right: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			::v-deep .el-form {
				width: 100%;
				display: flex;
				flex-direction: column;

				.meetingType {
					height: 40px;
					border-bottom: 1px solid #E5E5E5;
					padding: 0 15px;
					display: flex;
					justify-content: space-between;

					.docs {}


					.placeholder {
						color: #C0C4CC;
						display: block;
						cursor: pointer;
					}

					.add {
						color: #007AFF;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						width: 100px;
					}
				}

				.el-form-item {
					margin: 14px 0;
				}

				.doctors {
					position: relative;

					.delete {
						position: absolute;
						right: 0;
						top: 0;
						transform: translateY(-150%);
						padding: 0;
						display: none;
					}

					&:hover {
						.delete {
							display: block;
						}
					}
				}

				.attendingDoctor {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					margin-top: 30px;

					.label {
						font-size: 18px;
					}

					.option {
						margin-left: 20px;
						display: flex;
						align-items: center;

						.addStanding {
							padding: 4px 5px;
							font-size: 12px;
						}
					}
				}

				.el-form-item__label {
					padding: 0;
					line-height: 1;
					color: #112950;

					&:before {
						display: none;
					}
				}

				.el-form-item__content {
					margin-top: 10px;
					background-color: #F6F7F9;
					padding: 4px 0;
					border-radius: 8px;
				}

				.el-input__inner {
					border: none;
					border-radius: 0;
					background-color: transparent;
				}

				.el-input-group__append {
					border: none;
					background-color: transparent;
				}

				.el-checkbox {
					display: flex;
					align-items: center;
				}

				.meetingType {
					border-bottom: 0;
				}

				.el-radio-group {
					padding: 0 15px;
				}
			}

		}

		.right {
			// width: calc(33.33% - 20px);
			flex: 1;
			padding: 20px;
			margin-left: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			::v-deep .el-upload {
				width: 300px;
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: center;

				.el-icon-plus {
					border: 2px dashed #f5f5f5;
					border-radius: 10px;
					width: 300px;
					height: 200px;
				}
			}

			.el-icon-plus {
				width: 300px;
				height: 200px;
				font-size: 50px;
				padding: 20px;
				border: 1px dashed #eee;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.avatar {
				width: 300px;
				height: 200px;
				object-fit: cover;
				border-radius: 10px;
			}
		}

		.options {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 40px 0 20px 0;

			.el-button {
				width: 300px;
				height: 50px;

			}

			.next {
				background-color: #007AFF;
				color: #fff;
			}

			.back {}
		}

		.el-dialog__wrapper {
			overflow: hidden;
		}

		::v-deep .el-dialog {
			margin: 50vh auto 0 !important;
			max-height: 90vh;
			transform: translateY(-50%);
			overflow: hidden;
			overflow-y: auto;
			width: 1100px;

			// .el-dialog {
			// 	width: 100%;
			// 	height: 100%;
			// 	margin: 0;
			// }

			.el-dialog__header {
				padding: 0;
			}

			.el-dialog__body {
				display: flex;
				height: 100%;
				flex-direction: column;
			}

			.title {
				font-size: 20px;
				margin-bottom: 20px;
			}

			.searth {
				display: flex;
				flex-direction: row;
				margin-bottom: 20px;

				.el-input {
					flex: 3;
					margin-right: 20px;
				}

				.el-select {
					flex: 2;
					margin-right: 20px;
				}

				.el-button {}
			}

			.list {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-bottom: 30px;
				justify-content: flex-start;
				align-items: flex-start;

				.item {
					width: 49%;
					height: 110px;
					display: flex;
					cursor: pointer;
					transition: all .3s;
					padding: 20px;
					margin-bottom: 10px;
					margin-right: 2%;
					border-bottom: 1px solid #E5E5E5;

					&:nth-of-type(2n) {
						margin-right: 0;
					}

					&:hover {
						box-shadow: 0 0 10px rgba(0, 0, 0, .1);
						border-color: rgb(0, 122, 255);
					}

					* {
						cursor: pointer;
					}

					img {
						width: 68px;
						height: 68px;
						padding: 20px;
						border-radius: 10px;
						background-color: rgb(0, 122, 255);
						margin-right: 10px;
					}

					.docInfo {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: space-between;
						padding: 3px 5px;
						width: 100%;
						position: relative;

						.icon {
							position: absolute;
							top: 50%;
							right: 10px;
							transform: translateY(-50%);
						}

						.name {
							font-size: 18px;
							display: flex;
							flex-direction: row;
						}

						.hospital {
							font-size: 15px;
							display: flex;
							flex-direction: row;
						}

						.tit {
							font-size: 14px;
							display: flex;
							flex-direction: row;

							span {}
						}
					}
				}

				.null {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding: 100px;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
						margin-top: 30px;
					}
				}
			}

			.pagination {
				text-align: center;
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: flex-end;
			}

			.mapBox {
				width: 100%;
				height: 100%;
				overflow-y: auto;
				display: flex;
				flex-direction: column;

				.searthBox {
					display: flex;
					margin: 5px 0 10px 0;

					.el-autocomplete {
						flex: 1;
					}
				}

				.map {
					flex: 1;
					display: flex;
					flex-direction: row;

					.mapView {
						flex: 1;
					}

					.bmLocalSearch {
						height: 100%;
						max-width: 260px;

						>div {
							height: 100%;
						}
					}
				}
			}
		}
	}
</style>